import React from 'react';
import PropTypes from 'prop-types';
import { Survey, ReactSurveyElement, ReactElementFactory } from 'survey-react-ui';
import { Model } from 'survey-core';
import { createApi as createSurveyParticipantsApi } from '../../apis/survey_participants/api';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';
import { themeJSON } from './theme_json';

const ProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 2,
}));

class PercentageProgressBar extends ReactSurveyElement {
  render() {
    return (
      <>
        <Stack direction="row" spacing={1} alignItems="center" sx={{ padding: '0' }}>
          <ProgressBar variant="determinate" value={this.props.survey.progressValue} style={{ flex: 1 }} />
          <span className="att-dark-blue">{this.props.survey.progressValue}%</span>
        </Stack>
      </>
    );
  }
}

ReactElementFactory.Instance.registerElement('sv-progressbar-percentage', (props) => {
  return React.createElement(PercentageProgressBar, props);
});

const SurveyTaker = ({ surveyContent, surveyId, participantId, response, currentPage, isCompleted, token }) => {
  const surveyParticipantsApi = createSurveyParticipantsApi(`/survey_participants`);

  const content = surveyContent;
  content['progressBarType'] = 'questions';

  // Note: These settings should be set in the survey designer, but they are not being saved or loaded properly.
  // TODO: Make sure that all static defaults are set in the survey designer.
  content['focusFirstQuestionAutomatic'] = false;
  content['showCompletedPage'] = false;
  content['completedHtml'] = '';
  content['questionTitlePattern'] = 'numRequireTitle';

  // make sure that all matrix type questions have a rowTitleWidth of 250px
  if (content.pages) {
    content.pages.forEach((page) => {
      if (page.elements) {
        page.elements.forEach((element) => {
          if (element.type === 'matrix' || element.type === 'matrixdropdown') {
            element.rowTitleWidth = '250px';
          }
        });
      }
    });
  }

  const surveyModel = new Model(content);

  surveyModel.data = response;
  if (currentPage) {
    surveyModel.currentPageNo = currentPage;
  }

  surveyModel.addLayoutElement({
    id: 'progressbar-percentage',
    component: 'sv-progressbar-percentage',
    container: 'contentTop',
    data: {
      model: surveyModel,
      isCompleted,
    },
  });

  surveyModel.onComplete.add(async (sender) => {
    try {
      const { data } = sender;
      await surveyParticipantsApi.complete(participantId);
      window.location = `/surveys/completed_survey?token=${token}`;
    } catch (error) {
      console.error('An error occurred:', error);
    }
  });

  surveyModel.onValueChanged.add(async (sender) => {
    try {
      const { data } = sender;
      const displayValues = {};

      // Get the display values for the questions
      // TODO: Only used for public surveys atm.
      Object.keys(data).forEach((key) => {
        const question = sender.getQuestionByName(key);
        if (question) {
          displayValues[key] = question.getDisplayValue();
        }
      });

      const participant = await surveyParticipantsApi.update(participantId, {
        current_page_no: surveyModel.currentPageNo,
        response: data,
        display_values: displayValues,
      });
      surveyModel.data = participant.response;
    } catch (error) {
      console.error('Update call failed:', error);
      if (error.redirect_url) {
        window.location.href = error.redirect_url;
      }
    }
  });

  surveyModel.applyTheme(themeJSON);

  // Failsafe to prevent users from editing the completed survey in case they somehow get to this page.
  if (isCompleted) {
    surveyModel.mode = 'display';
  }

  return (
    <div className="survey survey--taker">
      <Survey model={surveyModel} />
    </div>
  );
};

SurveyTaker.propTypes = {
  surveyContent: PropTypes.object.isRequired,
  surveyId: PropTypes.number.isRequired,
  participantId: PropTypes.number.isRequired,
  response: PropTypes.object,
  currentPage: PropTypes.number,
  isCompleted: PropTypes.bool.isRequired,
  token: PropTypes.string.isRequired,
};

export default SurveyTaker;
